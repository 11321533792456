import React, { Component } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { buildBlocks } from "../services/builder"
import { CoreFields } from "./fragment" // eslint-disable-line

class Post extends Component {
  render() {
    this.props.pageContext.pageTitle = this.props.data.wp.post.Meta.title
      ? this.props.data.wp.post.Meta.title
      : this.props.data.wp.post.title
    const blocks = buildBlocks(
      this.props.data.wp.post.blocks,
      {},
      this.props.pageContext
    )

    return (
      <Layout
        meta={this.props.data.wp.post.Meta}
        path={this.props.pageContext.pagePath}
        cta={this.props.data.wp.post.PageCTA}
        title={this.props.data.wp.post.title}
        themeOptions={this.props.data.wp.themeOptions}
        ii={this.props.data.wp.themeOptions.ThemeOptions.individualInnsBranding}
        pageContext={this.props.pageContext}
      >
        {blocks}
      </Layout>
    )
  }
}

export const query = graphql`
  query($id: ID!) {
    wp {
      post(id: $id) {
        id
        slug
        title
        Meta {
          fieldGroupName
          metaDescription
          metaTitle
          ogDescription
          ogTitle
          twitterCardType
          twitterDescription
          twitterTitle
          twitterImage {
            uri
            sourceUrl
          }
          ogImage {
            uri
            sourceUrl
          }
        }
        PageCTA {
          actionType
          ctaText
          fieldGroupName
          link {
            url
          }
        }
        ... on wp_BlockEditorContentNode {
          blocks {
            ...CoreBlock
            ...CoreFields
          }
        }
      }
      themeOptions {
        accommodationActive
        roomBookingCTAText
        roomBookingImage
        roomBookingImageText
        roomBookingLink
        tableBookingAccountId
        tableBookingActive
        tableBookingBrandId
        tableBookingCTAText
        tableBookingImage
        tableBookingImageText
        tableBookingRestaurantId
        liveResSiteId
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
          individualInnsBranding
        }
      }
    }
  }
`

export default Post
